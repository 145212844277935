<template>
  <div class="in-workbench">
    <div class="left">
      <el-button v-if="Permission" type="primary" @click="dialog" style="text-align: left">上传文件</el-button>
      <el-dialog title="文件上传窗口" :visible.sync="UpDateUser" :append-to-body="true" width="50%"
                 top="calc((100vh - 600px) / 2)" :center="true" :close-on-click-modal=false>
        <el-form style="text-align: center">
          <el-row style="display:inline-block;">
            <el-col :xs=24 :sm=12 style="display:inline-block;">
              <el-upload style="display:inline-block;"
                         class="upload-demo"
                         drag
                         :action=UploadUrl
                         :data=Uploaddata
                         :on-success="handleAvatarSuccess"
                         :file-list="fileList"
                         :auto-upload="true"
                         multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <!--              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
              </el-upload>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">

        <el-button type="danger" @click="back">上传完成</el-button>
      </span>
      </el-dialog>
    </div>
    <div class="blank20"></div>
    <template>
      <el-table
        :data="tableData"
        style="width: 100%">

        <el-table-column
          label="文件名"
          width="500" prop="name">
        </el-table-column>
        <el-table-column
          label="上传时间"
          width="300">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.gmtCreate | dateFormater }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="文件类型"
          width="500" prop="category">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link v-if="Permission" @click="deleteFile(scope.row)">删除</el-link>
            <el-tag style="margin-left: 10px"><a :href="'https://' + scope.row.url" target="_blank">下载</a></el-tag>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="block">
      <span class="demonstration"></span>
      <el-pagination
        @size-change="handleSizeChange"
        :current-page.sync="pages" @current-change="handleCurrentChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="pagetotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import clientUtils from "../../js/clientUtils";
import enums from "../../js/enums";

export default {
  name: "Tes",
  data: function () {
    return {
      Uploaddata: {},
      pages: 0,
      Permission: false,
      fillpage: 1,
      pageflag: true,
      pagetotal: '',
      fileList: [],
      Hyperlinks: '',
      DownloadUrl: null,
      UpDateUser: false,
      fileUrl: '',
      categoryName: '',
      buttonflag: false,
      options: [{id: 1, name: "易损品"}, {id: 2, name: "消耗品"}],
      tableData: [],
      UploadUrl: "http://39.103.237.127:8160/system/file/upload/oss/" + clientUtils.getLocalUserInfo().id,
    }
  },
  filters: {
    dateFormater: function (datetime) {
      return clientUtils.formatDatetime(datetime, enums.datetimeFormat.FULL_CHS)
    }
  },

  methods: {
    dialog() {
      this.UpDateUser = true
    },
    back() {
      this.UpDateUser = false
      this.fileList = []
      this.pagetotal
    },
    deleteFile(info) {
      const that = this;
      this.$confirm("确定要删除" + info.name + "这条文件记录吗?", clientUtils.Tip, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        clientUtils.post('/system/ihsFile/removeById?id=' + info.id, null, function (res) {
          if (res.data != null) {
            clientUtils.popSuccessMessage("删除成功");
            that.Variety()
          }
        })
      })
    },
    Variety: function () {
      const that = this;
      console.log(that.pagetotal)
      if (that.pagetotal % 10 == 1) {
        that.fillpage = that.fillpage - 1
        console.log("ye" + that.fillpage)
        that.fileUrl = '/system/ihsFile/selectPage?current=' + that.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName
      }
      clientUtils.get(that.fileUrl, function (res) {
        if (res.data) {
          console.log(res.data.data);
          that.tableData = res.data.data.records;
          that.pagetotal = parseInt(res.data.data.total);
          console.log("bianhua" + that.pagetotal)
        }
      });
    },
    handleAvatarSuccess(res, file) {
      location.reload()
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
      this.fileList = []
      this.UpDateUser = false
      this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
      const that = this;
      console.log(this.fileUrl);
      clientUtils.get(this.fileUrl, function (res) {
        if (res.data != null) {
          that.pagetotal = parseInt(res.data.data.total);
          that.tableData = res.data.data.records;
          console.log(that.pagetotal);
          console.log(that.tableData)
        }
      })

    },
    page() {

    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.subtatol = `${val}`
      console.log("条数" + this.subtatol)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      console.log(val);
      this.fillpage = `${val}`;
      console.log('页码' + this.fillpage);
      this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
      this.pageflag = false;
      const that = this;
      console.log(this.fileUrl);
      clientUtils.get(this.fileUrl, function (res) {
        if (res.data != null) {
          // that.pagetotal = res.data.data.total;
          that.tableData = res.data.data.records;
          console.log(that.tableData)
        }
      })
    },
  },
  mounted() {
    const category = this.$route.params.category;
    console.log('用户名' + clientUtils.getLocalUserInfo().username);
    console.log('这是' + category);
    switch (category) {
      case 'water':
        this.categoryName = '上水通知';
        this.Uploaddata.uploadKey = '上水通知';
        this.$emit('selectMenu', this.$route.path, '上水通知');
        console.log('路径' + this.$route.path);
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log(this.fileUrl)
        break;
      case 'contingency':
        this.categoryName = '专业计划';
        this.Uploaddata.uploadKey = '专业计划';
        this.$emit('selectMenu', this.$route.path, '专业计划');
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'rate':
        this.categoryName = '进度';
        this.Uploaddata.uploadKey = '进度';
        this.$emit('selectMenu', this.$route.path, '进度');
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log(this.fileUrl)
        console.log('路径' + this.$route.path);
        break;
      case 'project':
        this.categoryName = '工程合同';
        this.Uploaddata.uploadKey = '工程合同';
        this.$emit('selectMenu', this.$route.path, '工程合同');
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'weekPlan':
        this.categoryName = '专业计划';
        this.$emit('selectMenu', this.$route.path, '专业计划');
        this.Uploaddata.uploadKey = '专业计划';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'buy':
        this.categoryName = '购销合同';
        this.Uploaddata.uploadKey = '购销合同';
        this.$emit('selectMenu', this.$route.path, '购销合同');
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'context':
        this.categoryName = '技术交底';
        this.Uploaddata.uploadKey = '技术交底';
        this.$emit('selectMenu', this.$route.path, '技术交底');
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'ask':
        this.categoryName = '监管要求';
        this.Uploaddata.uploadKey = '监管要求';
        this.$emit('selectMenu', this.$route.path, '监管要求');
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'doss':
        this.categoryName = '档案管理';
        this.Uploaddata.uploadKey = '档案管理';
        this.$emit('selectMenu', this.$route.path, '档案管理');
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'note':
        this.categoryName = '记事本';
        this.Uploaddata.uploadKey = '记事本';
        this.$emit('selectMenu', this.$route.path, '记事本');
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'personalFiles':
        this.categoryName = '日常计划';
        this.$emit('selectMenu', this.$route.path, '日常计划');
        this.Uploaddata.uploadKey = '日常计划';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'bloc':
        this.categoryName = '集团模板';
        this.$emit('selectMenu', this.$route.path, '集团模板');
        this.Uploaddata.uploadKey = '集团模板';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'emergency':
        this.categoryName = '应急预案';
        this.$emit('selectMenu', this.$route.path, '应急预案');
        this.Uploaddata.uploadKey = '应急预案';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'company':
        this.categoryName = '公司模板';
        this.$emit('selectMenu', this.$route.path, '公司模板');
        this.Uploaddata.uploadKey = '公司模板';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'how':
        this.categoryName = '知识培训';
        this.$emit('selectMenu', this.$route.path, '知识培训');
        this.Uploaddata.uploadKey = '知识培训';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'personal':
        this.categoryName = '个人文件';
        this.$emit('selectMenu', this.$route.path, '个人文件');
        this.Uploaddata.uploadKey = '个人文件';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        break;
      case 'import':
        this.categoryName = '付款类';
        this.$emit('selectMenu', this.$route.path, '重点工作-付款类');
        this.Uploaddata.uploadKey = '付款类';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'importone':
        this.categoryName = '工程类';
        this.$emit('selectMenu', this.$route.path, '重点工作-工程类');
        this.Uploaddata.uploadKey = '工程类';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'importtow':
        this.categoryName = '合同类';
        this.$emit('selectMenu', this.$route.path, '重点工作-合同类');
        this.Uploaddata.uploadKey = '合同类';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'importthree':
        this.categoryName = '新增类';
        this.$emit('selectMenu', this.$route.path, '重点工作-新增类');
        this.Uploaddata.uploadKey = '新增类';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'importfour':
        this.categoryName = '资产类';
        this.$emit('selectMenu', this.$route.path, '重点工作-资产类');
        this.Uploaddata.uploadKey = '资产类';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'modification':
        this.categoryName = '改造工程';
        this.$emit('selectMenu', this.$route.path, '改造工程');
        this.Uploaddata.uploadKey = '改造工程';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'expand':
        this.categoryName = '扩建工程';
        this.$emit('selectMenu', this.$route.path, '扩建工程');
        this.Uploaddata.uploadKey = '扩建工程';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'cost':
        this.categoryName = '责任成本';
        this.$emit('selectMenu', this.$route.path, '责任成本');
        this.Uploaddata.uploadKey = '责任成本';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;

      case 'addressBook':
        this.categoryName = '通讯录';
        this.$emit('selectMenu', this.$route.path, '通讯录');
        this.Uploaddata.uploadKey = '通讯录';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'Post':
        this.categoryName = '岗位构成';
        this.$emit('selectMenu', this.$route.path, '岗位构成');
        this.Uploaddata.uploadKey = '岗位构成';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'day':
        this.categoryName = '日报表';
        this.$emit('selectMenu', this.$route.path, '日报表');
        this.Uploaddata.uploadKey = '日报表';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'week':
        this.categoryName = '周报表';
        this.$emit('selectMenu', this.$route.path, '周报表');
        this.Uploaddata.uploadKey = '周报表';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'yue':
        this.categoryName = '月报表';
        this.$emit('selectMenu', this.$route.path, '月报表');
        this.Uploaddata.uploadKey = '月报表';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;
      case 'main':
        this.categoryName = '服务部总指标';
        this.$emit('selectMenu', this.$route.path, '服务部总指标');
        this.Uploaddata.uploadKey = '服务部总指标';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;

      case 'November':
        this.categoryName = '十一月';
        this.$emit('selectMenu', this.$route.path, '十一月');
        this.Uploaddata.uploadKey = '十一月';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;

      case 'December':
        this.categoryName = '十二月';
        this.$emit('selectMenu', this.$route.path, '十二月');
        this.Uploaddata.uploadKey = '十二月';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;

      case 'January':
        this.categoryName = '一月';
        this.$emit('selectMenu', this.$route.path, '一月');
        this.Uploaddata.uploadKey = '一月';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;

      case 'February':
        this.categoryName = '二月';
        this.$emit('selectMenu', this.$route.path, '二月');
        this.Uploaddata.uploadKey = '二月';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;

      case 'March':
        this.categoryName = '三月';
        this.$emit('selectMenu', this.$route.path, '三月');
        this.Uploaddata.uploadKey = '三月';
        this.fileUrl = '/system/ihsFile/selectPage?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id + '&type=' + this.categoryName;
        console.log('路径' + this.$route.path);
        console.log(this.fileUrl)
        break;

      default:
        this.categoryName = ''
    }
    if (this.categoryName === '') {
      this.$router.push('/default')
    } else {
      // 参数正确
      this.category = category
    }

    if (this.categoryName === "个人文件") {
      const that = this;
      clientUtils.get(this.fileUrl, function (res) {
        if (res.data != null) {
          that.pagetotal = parseInt(res.data.data.total);
          that.tableData = res.data.data.records
        }
      })
        that.Permission = true
    } else {
      const that = this;
      clientUtils.get(this.fileUrl, function (res) {
        if (res.data != null) {
          that.pagetotal = parseInt(res.data.data.total);
          that.tableData = res.data.data.records
        }
      })
      clientUtils.get('/system/user/isUserVip?id=' + clientUtils.getLocalUserInfo().id + '&type=S_VIP', function (res) {
        that.Permission = res.data
        console.log(that.Permission)
      })
    }


  }
}
</script>

<style scoped>
.left {
  text-align: left;
}

</style>
